<template>
	<div id="courseSystem">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">课程体系</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="head u-f-item">
				<span class="span">选择条件</span>
				
				<el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="semester" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeSemester">
					<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == gradeIndex ? 'active' : ''"
						v-for="(item, index) in gradeMenu"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content u-f">
				<div class="building-list">
					<div class="item" :class="items.id == grade_id ? 'active' : ''" v-for="items in gradeList" :label="items.name" :key="items.id" @click="changeGrade(items.id)">
						{{ items.name }}
					</div>
				</div>
				<div class="divide">
					<div class="content1" v-if="gradeIndex==0">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total }}条数据
							</div>
							<div class="u-f-item">
								<el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;" @change="sousuoList">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button size="small" v-if="gradeIndex==0" @click="addtime=true">添加课程</el-button>
							</div>
						</div>
						<el-table
							:data="list"
							height="80%"
							ref="multipleTable"
							tooltip-effect="dark"
							@selection-change="handleSelectionChange"
							style="width: 100%;border: 1px solid #EEEEEE;"
							key="2"
						>
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column label="序号" type="index" width="150" align="center"></el-table-column>
							<el-table-column prop="title" label="学科" align="center"></el-table-column>
							<el-table-column prop="introduction" label="描述" align="center"></el-table-column>
							<el-table-column prop="credits" label="学分" align="center"></el-table-column>
							<el-table-column label="操作" align="center" prop="">
								<template slot-scope="scope">
									<el-button @click="editOpen(scope.row)" type="text" size="small">编辑</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<div class="u-f-item">
								<el-button type="danger" plain size="mini" @click="delStu">删除</el-button>
							</div>
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
					
					<div class="content1" v-if="gradeIndex==1">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total1 }}条数据
							</div>
							<div class="u-f-item">
								<el-input placeholder="请输入内容" v-model="sousuo1" size="small" style="margin-right: 10px;" @change="sousuoList1">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
							</div>
						</div>
						<el-table
							:data="studentCourse"
							height="80%"
							style="width: 100%;border: 1px solid #EEEEEE;"
						>
							<el-table-column label="序号" type="index" width="150" align="center"></el-table-column>
							<el-table-column prop="year_name" label="学年" align="center"></el-table-column>
							<el-table-column prop="semester_name" label="学期" align="center"></el-table-column>
							<el-table-column prop="realname" label="姓名" align="center"></el-table-column>
							<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
							<el-table-column prop="sex_text" label="性别" align="center"></el-table-column>
							<el-table-column prop="credits" label="学分" align="center"></el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button @click="editCredits(scope.row)" type="text" size="small">修改学分</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange1"
								@current-change="handleCurrentChange1"
								:current-page.sync="page1"
								:page-size="limit1"
								layout="total, prev, pager, next"
								:total="total1"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
			<el-dialog title="添加课程" :visible.sync="addtime" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							名称
						</div>
						<el-input v-model="title" placeholder="请输入得分项名称" style="width: 85%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							描述
						</div>
						<el-input v-model="introduction" placeholder="请输入得分项描述" style="width: 85%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学分
						</div>
						<el-input-number v-model="credits" :min="0" :max="10" label="学分"></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeaddtime" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="add">确 定</el-button>
				</span>
			</el-dialog>
			
			<el-dialog title="编辑课程" :visible.sync="eidttime" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							名称
						</div>
						<el-input v-model="title" placeholder="请输入得分项名称" style="width: 85%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							描述
						</div>
						<el-input v-model="introduction" placeholder="请输入得分项描述" style="width: 85%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学分
						</div>
						<el-input-number v-model="credits" :min="0" :max="10" label="学分"></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeidttime" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="edit">确 定</el-button>
				</span>
			</el-dialog>
		
			<el-dialog title="修改学分" :visible.sync="Course" width="500px" :before-close="handleClose">
				<div class="edit-box" v-for="(item,index) in CourseList" :key="index">
					<div class="u-f-item input-box" style="padding-left: 80px;">
						<div class="input-lebal">
							{{item.title}}
						</div>
						<el-input-number v-model="item.credits" :min="0" :max="10" label="学分"></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="Course = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="editCourse">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	export default {
		components: {
			index
		},
		data() {
			return {
				gradeMenu: [
					{ name: '学分设置' },
					{ name: '学生学分' }
				],
				gradeIndex: 0,
				condition: '',
				conditionList: [],
				year: '',
				yearList: [],
				semester: '',
				semesterList: [],
				list:[],
				total:0,
				sousuo:'',
				gradeList:[],
				grade_id:'',
				page:1,
				limit:10,
				addtime:false,
				title:'',
				introduction:'',
				credits:0,
				eidttime:false,
				CourseSystemId:'',
				ids:[],
				studentCourse:[],
				sousuo1:'',
				page1:1,
				limit1:10,
				total1:0,
				Course:false,
				CourseList:[],
				distribution_id:''
			}
		},
		mounted() {
			this.getYearList();
		},
		methods: {
			sousuoList1(){
				this.page1 = 1;
				this.getStudentCourseList()
			},
			handleClose(done){
				this.introduction = '';
				this.credits = '';
				this.title = '';
				done()
			},
			// 选择
			handleSelectionChange(val) {
				let arr = [];
				for (let i in val) {
					arr.push(val[i].id);
				}
				this.ids = arr;
				console.log(this.ids);
			},
			add(){
				let data= {
					semester_id:this.semester,
					grade_id:this.grade_id,
					title:this.title,
					introduction:this.introduction,
					credits:this.credits
				}
				this.$api.student.addCourseSystem(data).then(res=>{
					if(res.data.code==1){
						this.addtime = false;
						this.$message.success('添加成功');
						this.page = 1;
						this.getCourseSystemList()
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			twacherTap(index) {
				this.grade_id = this.gradeList[0].id;
				this.gradeIndex = index;
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getCourseSystemList();
			},
			handleSizeChange1(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange1(val) {
				this.page1 = val;
				this.getStudentCourseList();
			},
			// 学年列表
			getYearList() {
				this.$api.setting.getSemesterList().then(res => {
					if (res.data.code == 1) {
						this.yearList = res.data.data;
						if (res.data.data.length) {
							if (this.yearList.filter(item => item.is_default == 2).length > 0) {
								this.year = this.yearList.filter(item => item.is_default == 2)[0].id;
							} else {
								this.year = this.yearList[0].id;
							}
						} else {
							this.year = '';
						}
						this.getSemesterList();
						this.getGradeList();
					}
				});
			},
			changeYear(e) {
				this.year = e;
				this.getGradeList();
				this.getSemesterList();
			},
			// 学期列表
			getSemesterList() {
				this.$api.setting.getXueQiList({ year_id: this.year }).then(res => {
					if (res.data.code == 1) {
						this.semesterList = res.data.data;
						if (res.data.data.length) {
							if (this.semesterList.filter(item => item.is_default == 2).length > 0) {
								this.semester = this.semesterList.filter(item => item.is_default == 2)[0].id;
							} else {
								this.semester = this.semesterList[0].id;
							}
						} else {
							this.semester = '';
						}
						this.getCourseSystemList()
						this.getStudentCourseList()
					}
				});
			},
			changeSemester(e) {
				this.semester = e;
				this.page = 1;
				this.getCourseSystemList()
				this.getStudentCourseList()
			},
			// 年级列表
			getGradeList() {
				this.$api.setting.getGradeList({ filter: JSON.stringify({ year_id: this.year }) }).then(res => {
					if (res.data.code == 1) {
						this.gradeList = res.data.data.rows;
						if (this.gradeList.length) {
							this.grade_id = this.gradeList[0].id;
						} else {
							this.grade_id = '';
						}
						this.getCourseSystemList()
						this.getStudentCourseList()
					}
				});
			},
			sousuoList(){
				this.page = 1;
				this.getCourseSystemList()
			},
			//获取学分设置列表
			getCourseSystemList(){
				let data = {
					semester_id:this.semester,
					grade_id:this.grade_id,
					page:this.page,
					limit:this.limit
				}
				if(this.sousuo){data.keyword = this.sousuo}
				this.$api.student.getCourseSystemList(data).then(res=>{
					if(res.data.code==1){
						this.list = res.data.data.rows;
						this.total = res.data.data.total;
					}
				})
			},
			changeGrade(id){
				this.grade_id = id;
				this.page = 1;
				this.getCourseSystemList();
				this.getStudentCourseList()
			},
			// 打开编辑
			editOpen(item){
				this.CourseSystemId = item.id;
				this.$api.student.getCourseInfoSet({
					id:item.id
				}).then(res=>{
					if(res.data.code==1){
						this.title = res.data.data.title;
						this.introduction = res.data.data.introduction;
						this.credits = res.data.data.credits;
						this.eidttime = true;
					}
				})
			},
			// 编辑学分设置
			edit(){
				this.$api.student.editSetCourseSystem({
					id:this.CourseSystemId,
					title:this.title,
					introduction:this.introduction,
					credits:this.credits
				}).then(res=>{
					if(res.data.code==1){
						this.$message.success('修改成功');
						this.introduction = '';
						this.credits = '';
						this.title = '';
						this.eidttime = false;
						this.getCourseSystemList()
					}else{
						this.$message.error(res.data.msg)
					}
				})
			},
			closeaddtime(){
				this.introduction = '';
				this.credits = '';
				this.title = '';
				this.addtime = false;
			},
			closeidttime(){
				this.introduction = '';
				this.credits = '';
				this.title = '';
				this.eidttime = false;
			},
			// 删除
			delStu(){
				let _this = this;
				if (this.ids.length) {
					this.$confirm('确定要删除？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消'
					}).then(() => {
						this.$api.student
							.delSetCourseSystem({
								semester_id: this.semester, // 	2 	string 	是 	学年ID
								grade_id:this.grade_id,
								ids: this.ids
							})
							.then(res => {
								if (res.data.code == 1) {
									_this.$message.success('删除成功');
									_this.getCourseSystemList();
								} else {
									_this.$message.error(res.data.msg);
								}
							});
					});
				} else {
					this.$message.error('请选择要删除的学分设置');
				}
			},
			// 获取学生学分列表
			getStudentCourseList(){
				let data = {
					semester_id:this.semester,
					grade_id:this.grade_id,
					page:this.page1,
					limit:this.limit1
				}
				if(this.sousuo1){data.keyword = this.sousuo1}
				this.$api.student.getstudentCreditsList(data).then(res=>{
					if(res.data.code==1){
						this.studentCourse = res.data.data.rows;
						this.total1 = res.data.data.total;
					}
				})
			},
			editCredits(item){
				this.distribution_id = item.distribution_id;
				this.$api.student.getstudentInfoCredits({
					distribution_id:item.distribution_id
				}).then(res=>{
					this.CourseList = res.data.data.set_list;
					this.Course = true;
				})
			},
			editCourse(){
				let credits = []
				for(let i in this.CourseList){
					credits.push({
						set_id:this.CourseList[i].id,
						credits:this.CourseList[i].credits
					})
				}
				let data = {
					distribution_id:this.distribution_id,
					credits:credits
				}
				this.$api.student.editCredits(data).then(res=>{
					if(res.data.code==1){
						this.$message.success('修改成功')
						this.getStudentCourseList()
						this.Course = false;
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	#courseSystem{
		.head {
			background-color: #ffffff;
			padding: 10px 30px;
			margin-top: 15px;
			.span {
				margin-right: 10px;
				background-color: #f9f9f9;
				color: #8d9da6;
				padding: 3px 10px;
			}
		}
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 0.52vw;
			margin-top: 1.04vw;
			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				&div:last-child {
					border-right: none;
				}
				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}
				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.58125vw;
				}
			}
		}
		.content {
			background-color: #ffffff;
			height: 75vh;
			padding: 0 30px;
			border-top: 1px solid #EEEEEE;
			.building-list {
				padding: 25px 25px 25px 0;
				width: 15%;
				border-right: 1px solid #eee;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-bottom: 15px;
					background-color: #f8f8f8;
				}
				.item:hover {
					color: #007aff;
					background: #e4f1ff;
					cursor: pointer;
				}
				.active {
					color: #007aff;
					background: #e4f1ff;
				}
			}
			.divide {
				width: 80%;
				padding: 30px 0 0 30px;
				.content1 {
					background-color: #ffffff;
					overflow-y: auto;
					height: 100%;
					.title {
						padding: 0 0 15px 0;
						.btn {
							background-color: #f5f5f5;
							padding: 5px;
						}
						.btn:hover {
							color: #4998ff;
							background: #e4f1ff;
							cursor: pointer;
						}
					}
					.el-table th > .cell,
					.el-table .cell {
						overflow: hidden; /*超出部分隐藏*/
						white-space: nowrap; /*不换行*/
						text-overflow: ellipsis; /*超出部分文字以...显示*/
					}
					.el-table thead {
						background: #fafafa !important;
					}
					.el-table th {
						padding: 15px 5px !important;
						background: #fafafa !important;
					}
					.el-table::before {
						left: 0;
						bottom: 0;
						width: 100%;
						height: 0;
					}
					.el-table .btn {
						padding: 5px;
						text-align: center;
					}
				}
				
			}
		}
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0 20px 35px 20px;
			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 15px;
					.input-lebal {
						color: #aeb5b8;
						margin-right: 10px;
						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}
					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}
				}
				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}
				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}
				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
		
		.el-table__column-filter-trigger {
			.el-icon-arrow-down:before {
				content: '\E790';
				font-size: 16px;
			}
		}
	}
</style>
